import { takeLatest, all, } from 'redux-saga/effects'

// Reducers
import loginReducer from './Auth/reducer'
import reportsReducer from './Reports/reducer'
import notificationsReducer from './Notifications/reducer'
import accountReducer from './Account/reducer'
import merchantReducer from './Merchant/reducer'

// ActionTypes
import { LoginTypes, } from './Auth/actions'
import { ReportTypes, } from './Reports/actions'
import { AccountActionTypes, } from './Account/actions'
import { MerchantActionTypes, } from './Merchant/actions'

// Sagas
import * as LoginSagas from './Auth/sagas'
import * as AccountSagas from './Account/sagas'
import * as MerchantSagas from './Merchant/sagas'

const reducers = {
  auth: loginReducer,
  account: accountReducer,
  reports: reportsReducer,
  notifications: notificationsReducer,
  merchant: merchantReducer,
}

export default reducers

// Define which actions should be monitored, and what saga function to call in response to a relevent action.
export function* rootSaga() {
  yield all([
    takeLatest(LoginTypes.LOGIN_REQUEST, LoginSagas.loginRequest),
    takeLatest(LoginTypes.AUTHORIZE_USER_LOGIN, LoginSagas.authorizeUserLogin),
    takeLatest(LoginTypes.LOGOUT, LoginSagas.logout),
    takeLatest(LoginTypes.PASSIVE_LOGOUT, LoginSagas.passiveLogout),
    takeLatest(LoginTypes.LOGIN_REDIRECT, LoginSagas.loginRedirect),
    takeLatest(AccountActionTypes.FETCH_ACCOUNT_REQUEST, AccountSagas.fetchAccountInfo),
    takeLatest(AccountActionTypes.FETCH_ADMIN_ROLES, AccountSagas.fetchAdminRoles),
    takeLatest(AccountActionTypes.FETCH_SITE_SETTINGS, AccountSagas.fetchSiteSettings),
    takeLatest(AccountActionTypes.UPDATE_MERCHANT_USER_SETTINGS, AccountSagas.updateMerchantUserSettings),
    takeLatest(AccountActionTypes.UPDATE_LAST_LOGIN, AccountSagas.updateLastLogin),
    takeLatest(AccountActionTypes.ACCESS_RULES_REQUEST, AccountSagas.fetchAccessRules),
    takeLatest(AccountActionTypes.PERMISSIONS_REQUEST, AccountSagas.fetchPermissions),
    takeLatest(LoginTypes.RECOVER_ACCESS_REQUEST, LoginSagas.recoverAccess),
    takeLatest(LoginTypes.RESET_PASSWORD_REQUEST, LoginSagas.resetPassword),
    takeLatest(AccountActionTypes.SEND_MERCHANT_TOKEN_REQUEST, AccountSagas.sendMerchantToken),
    takeLatest(AccountActionTypes.VALIDATE_TOKEN, AccountSagas.validateToken),
    takeLatest(LoginTypes.REFRESH_USER_SESSION, LoginSagas.refreshUserSession),
    takeLatest(MerchantActionTypes.FETCH_MERCHANT_PROFILE, MerchantSagas.fetchMerchantProfile),
    takeLatest(MerchantActionTypes.FETCH_MERCHANT_ALERTS, MerchantSagas.fetchMerchantAlerts),
    takeLatest(MerchantActionTypes.SEND_BENEFICIAL_DATA, MerchantSagas.sendBeneficialData),
    takeLatest(MerchantActionTypes.FETCH_FULL_RATES_LIST, MerchantSagas.fetchFullRatesList),
    takeLatest(MerchantActionTypes.UPDATE_MERCHANT_STATUS, MerchantSagas.updateMerchantStatus),
    takeLatest(MerchantActionTypes.UPDATE_FUNDING_HOLD, MerchantSagas.updateFundingHold),
    takeLatest(MerchantActionTypes.REVERT_PROCESSOR, MerchantSagas.revertProcessor),
    takeLatest(MerchantActionTypes.ACCEPT_T_O_S, MerchantSagas.acceptTOS),
    takeLatest(MerchantActionTypes.UPLOAD_FILE, MerchantSagas.uploadFile),
    takeLatest(MerchantActionTypes.REMOVE_FILE, MerchantSagas.removeFile),
    takeLatest(AccountActionTypes.SET_MERCHANT_ACCOUNT_ID, AccountSagas.setMerchantAccountId),
    takeLatest(AccountActionTypes.GET_SELECTED_MERCHANT_ACCOUNT_ID, AccountSagas.getSelectedMerchantAccountId),
  ])
}
