import { call, } from 'redux-saga/effects'
import _ from 'lodash'
import moment from 'moment'

const buildSortString = (sorts) => {
  const results = []

  _.keys(sorts).forEach((key) => {
    const order = sorts[key] === 'DESC' ? '-' : ''
    results.push(`${order}${key}`)
  })

  return results.join(',')
}

const AdminApi = (api) => ({
  * submitMerchantProfile(merchantId, params) {
    return yield call(api.post, `/admin/merchants/${merchantId}`, params)
  },
  * submitMerchantProfileAdvanced(merchantId, params) {
    return yield call(api.post, `/admin/merchants/${merchantId}/advanced`, params)
  },
  * fetchMerchantList(pagination, status, searchValue, sorts, filters, dates) {
    const params = {}

    if (pagination) {
      const mappers = [
        { index: 0, key: 'page', value: pagination.page },
        { index: 0, key: 'per_page', value: pagination.perPage },
        { index: 1, key: 'status', value: status },
        { index: 2, key: 'merchant_search', value: searchValue },
        { index: 3, key: 'sort', value: buildSortString(sorts) },
        { index: 4, key: 'filters', value: filters },
        { index: 5, key: 'dates', value: dates },
      ]

      mappers.map((mapper) => {
        if (arguments[mapper.index]) {
          params[mapper.key] = mapper.value
        }
      })
    }

    if (!params.sort) {
      params.sort = 'businessCommonName,businessLegalName'
    }

    return yield call(api.get, '/admin/merchants', { params, })
  },

  * downloadMerchantList(status, searchValue, sorts, filters, dates) {
    const params = {}

    const mappers = [
      { index: 0, key: 'status', value: status, },
      { index: 1, key: 'merchant_search', value: searchValue, },
      { index: 2, key: 'sort', value: buildSortString(sorts), },
      { index: 3, key: 'filters', value: filters, },
      { index: 4, key: 'download', value: true, },
      { index: 5, key: 'dates', value: dates },
    ]

    mappers.map((mapper) => params[mapper.key] = mapper.value)

    return yield call(api.get, '/admin/merchants', { params, })
  },

  * fetchContactType({ merchantId }) {
    return yield call(api.get, `/merchants/${merchantId}/contact-type`)
  },

  * fetchCtaSummary({ merchantId }) {
    return yield call(api.get, `/merchants/${merchantId}/cta`)
  },

  * submitCtaSummary({ data }) {
    return yield call(api.post, `/merchants/${data.merchantId}/cta`, data)
  },

  * fetchMerchantRateList() {
    return yield call(api.get, '/admin/merchants/rate-list')
  },

  * downloadFundingHold() {
    return yield call(api.get, `/admin/merchants/funding-hold`)
  },

  * downloadInvalid1099K() {
    return yield call(api.get, `/admin/merchants/invalid-1099k`)
  },

  * downloadLimits() {
    return yield call(api.get, `/admin/limits`, {
      params: {
        download: true
      }
    })
  },


  * fetchPartnerList(pagination, status, partnerProp, searchValue, sorts) {
    const params = {}

    if (pagination) {
      const mappers = [
        { index: 0, key: 'page', value: pagination.page, },
        { index: 0, key: 'per_page', value: pagination.perPage, },
        { index: 1, key: 'status', value: status, },
        { index: 3, key: partnerProp, value: searchValue, },
        { index: 4, key: 'sort', value: buildSortString(sorts), },
      ]

      mappers.map((mapper) => {
        if (arguments[mapper.index]) {
          params[mapper.key] = mapper.value
        }
      })
    }

    return yield call(api.get, '/admin/partners', { params, })
  },

  * downloadPartnerList(status, partnerProp, searchValue, sorts) {
    return yield call(api.get, '/admin/partners', {
      params: {
        status,
        [partnerProp]: searchValue,
        sort: buildSortString(sorts),
        download: true,
      },
    })
  },

  * downloadRatesList(searchValue, sorts, filters) {
    const params = {}

    const mappers = [
      { index: 0, key: 'rates_search', value: searchValue, },
      { index: 1, key: 'sort', value: buildSortString(sorts), },
      { index: 2, key: 'filters', value: filters, },
    ]

    mappers.map((mapper) => {
      if (arguments[mapper.index]) {
        params[mapper.key] = mapper.value
      }
    })

    if (!params.sort) {
      params.sort = 'id,rateName'
    }

    return yield call(api.get, '/admin/rates/report', { params, })
  },

  * fetchRatesList(pagination, searchValue, sorts, filters) {
    const params = {}

    if (pagination) {
      const mappers = [
        { index: 0, key: 'page', value: pagination.page, },
        { index: 0, key: 'per_page', value: pagination.perPage, },
        { index: 1, key: 'rates_search', value: searchValue, },
        { index: 2, key: 'sort', value: buildSortString(sorts), },
        { index: 3, key: 'filters', value: filters, },
      ]

      mappers.map((mapper) => {
        if (arguments[mapper.index]) {
          params[mapper.key] = mapper.value
        }
      })
    }

    if (!params.sort) {
      params.sort = 'id,rateName'
    }

    return yield call(api.get, '/admin/rates', { params, })
  },

  * createRate(params) {
    return yield call(api.post, `/admin/rates`, params)
  },

  * fetchAccountsByRate(params) {
    return yield call(api.get, `/admin/rates/accounts`, { params, })
  },

  * fetchRateById(id) {
    return yield call(api.get, `/admin/rates/${id}`)
  },

  * fetchRatesByProcessorId(processorId) {
    return yield call(api.get, `/admin/rates/processor/${processorId}`)
  },

  * fetchRatePartnersById(id) {
    return yield call(api.get, `/admin/rates/${id}/partners`)
  },

  * generateMerchantLink(rate, partnerId) {
    return yield call(api.post, `/admin/rates/${partnerId}/link`, { rate, })
  },

  * updateRate(id, rate) {
    return yield call(api.post, `/admin/rates/${id}/update`, { rate, })
  },

  * fetchResidualRates() {
    return yield call(api.get, `/admin/rates/residual-rates`)
  },

  * convertToResidual(id, residualRateId) {
    return yield call(api.patch, `/admin/rates/${id}/convert-to-residual`, { isResidualRate: true, residualRateId, })
  },

  * fetchClients() {
    return yield call(api.get, '/admin/clients')
  },

  * fetchPartnerProfile(partnerId) {
    return yield call(api.get, `/admin/partners/${partnerId}`)
  },

  * fetchPartnerNotification(partnerId) {
    return yield call(api.get, `/admin/partners/${partnerId}/notifications`)
  },

  * createPartnerNotification(partnerId, params) {
    return yield call(api.post, `/admin/partners/${partnerId}/notifications`, params)
  },

  * deletePartnerNotification(partnerId) {
    return yield call(api.delete, `/admin/partners/${partnerId}/notifications`)
  },

  * submitPartnerProfile(partnerId, params) {
    return yield call(api.patch, `/admin/partners/${partnerId}`, params)
  },

  * updatePartnerStatus(partnerId, params) {
    return yield call(api.patch, `/admin/partners/${partnerId}/status`, params)
  },

  * createPartner(params) {
    return yield call(api.post, `/admin/partners`, params)
  },

  * fetchGrossSalesOverview(clientId, currency, startDate, endDate) {
    const endpoint = clientId ? `/admin/clients/${clientId}/transaction-gross-sales` : '/admin/transactions/gross-sales'
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
    }

    if (clientId) {
      params.id = clientId
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchACHReturnOverview(clientId, currency, startDate, endDate) {
    const endpoint = clientId ? `/admin/clients/${clientId}/ach-return-overview` : '/admin/ach-returns/overview'
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
    }

    if (clientId) {
      params.id = clientId
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchChargebackOverview(clientId, currency, startDate, endDate) {
    const endpoint = clientId ? `/admin/clients/${clientId}/chargeback-overview` : '/admin/disputes/overview'
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
    }

    if (clientId) {
      params.id = clientId
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchRetrievalOverview(clientId, currency, startDate, endDate) {
    const endpoint = clientId ? `/admin/clients/${clientId}/retrieval-overview` : '/admin/retrievals/overview'
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
    }

    if (clientId) {
      params.id = clientId
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchTransactionOverview(clientId, currency, startDate, endDate) {
    const endpoint = clientId ? `/admin/clients/${clientId}/transaction-overview` : '/admin/transactions/overview'
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
    }

    if (clientId) {
      params.id = clientId
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchAverageAmountOverview(clientId, currency, startDate, endDate) {
    const endpoint = clientId ? `/admin/clients/${clientId}/average-amount` : '/admin/transactions/average-amount'
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
    }

    if (clientId) {
      params.id = clientId
    }

    return yield call(api.get, endpoint, { params, })
  },

  * fetchTransactionsVolume(startDate, endDate, currency = 'USD', download = false, client = null) {
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
      download,
    }
    if (!client) return yield call(api.get, '/admin/transactions/type-volumes', { params, })
    return yield call(api.get, `/admin/clients/${client}/transaction-type-volumes`, { params, })
  },

  * fetchMonthlyVolume(startDate, endDate, currency = 'USD', download = false, client = null) {
    const params = {
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      currency,
      download,
    }

    if (!client) return yield call(api.get, '/admin/transactions/monthly-volumes', { params, })
    return {}
    // return yield call(api.get, `/admin/clients/${client}/transaction-type-volumes`, { params, })
  },

  * fetchVolumeAnalysis(currentDate, currency = 'USD') {
    const params = {
      currentDate,
      currency,
    }

    return yield call(api.get, '/admin/transactions/volume-analysis', { params, })
  },

  * fetchCardVolume(currentDate, currency = 'USD') {
    const params = {
      currentDate,
      currency,
    }

    return yield call(api.get, '/admin/transactions/card-volumes', { params, })
  },

  * fetchUsers(pagination, sort, searchValue = '', userType) {
    const { page, perPage, } = pagination

    const params = {
      per_page: perPage,
      page,
      group: userType,
      search: searchValue,
    }

    for (const property in sort) {
      if (sort.hasOwnProperty(property) && sort[property] !== '') {
        params.sort = property
        params.dir = sort[property]
      }
    }

    return yield call(api.get, '/v2/admin/users', { params, })
  },

  * fetchUserTypes() {
    return yield call(api.get, '/admin/users/groups', {})
  },

  * fetchUserById(id) {
    return yield call(api.get, `/v2/admin/users/${id}/info`)
  },

  * fetchUserPermissionListById(id) {
    return yield call(api.get, `/admin/permissions/list/${id}`)
  },

  * grantUserPermission({ userId, permissionId, }) {
    return yield call(api.patch, `/admin/permissions/${userId}/${permissionId}`)
  },

  * revokeUserPermission({ userId, permissionId, }) {
    return yield call(api.delete, `/admin/permissions/${userId}/${permissionId}`)
  },

  * grantUserRole({ userId, permissionId }) {
    return yield call(api.patch, `/admin/permissions/internal/${userId}/${permissionId}`)
  },

  * revokeUserRole({ userId, permissionId }) {
    return yield call(api.delete, `/admin/permissions/internal/${userId}/${permissionId}`)
  },

  * updateUserById(id, data) {
    return yield call(api.post, `/v2/admin/users/${id}/info`, data)
  },

  * updateUserEmailById(id, newEmail) {
    return yield call(api.post, `/v2/admin/users/${id}/email`, { newEmail, })
  },

  * newUser(userInfo) {
    const AdminUserParam = userInfo
    return yield call(api.post, '/v2/admin/users', AdminUserParam)
  },

  * exportMerchantUsers(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/merchantusers`)
  },

  * fetchMerchantListDropdown() {
    return yield call(api.get, '/admin/merchants/list')
  },

  * fetchMerchantListSearchDropdown(search) {
    const params = { search }
    return yield call(api.get, '/admin/merchants/list-search', { params, })
  },

  * fetchMerchantProfile(merchantId) {
    return yield call(api.get, `merchants/${merchantId}`)
  },

  * fetchMerchantRatesList(merchantId) {
    return yield call(api.get, `merchants/${merchantId}/rates`)
  },

  * fetchMerchantHistory(merchantId, page, filter) {
    const params = { page, filter }
    return yield call(api.get, `/admin/merchants/${merchantId}/history`, { params, })
  },

  * fetchUserHistory(merchantId, page, filter) {
    const params = { page, filter }
    return yield call(api.get, `/admin/merchants/${merchantId}/users-history`, { params, })
  },


  // * fetchMerchantGatewayStatus(merchantId, gatewayId, gatewayAltId, processorId) {
  //   const params = { gatewayId, gatewayAltId, processorId }
  //   return yield call(api.get, `/admin/merchants/${merchantId}/gatewaystatus`, { params, })
  // },

  * revertProcessor(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/revert-processor`, {
      selectedRateId: data.selectedRateId,
      selectedRateName: data.selectedRateName,
      selectedPayoutFrequency: data.selectedPayoutFrequency,
      selectedPayoutDay: data.selectedPayoutDay,
      oldProcessorGatewayId: data.oldProcessorGatewayId,
      oldProcessorGatewayAltId: data.oldProcessorGatewayAltId,
      oldProcessorId: data.oldProcessorId,
    })
  },

  * acceptTOS(merchantId) {
    return yield call(api.post, `/admin/merchants/${merchantId}/accept-tos`)
  },

  * fetchMerchantGrossVolume(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/gross-volume`)
  },

  * fetchMerchantRefundVolume(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/refund-volume`)
  },

  * fetchMerchantChargebackReturnVolume(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/chargeback-return-volume`)
  },

  * fetchMerchantCurrentDayVolume(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/volume/current-day`)
  },

  * fetchMerchantUsers(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/users`)
  },

  * updateMerchantStatus(merchantId, merchantStatus, closureReason = null) {
    return yield call(api.post, `/admin/merchants/${merchantId}/status`,
      { status: merchantStatus, reason: closureReason, }
    )
  },

  * updateMerchantPOS(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/pos`, { data, })
  },

  * updateMerchantUseDestinationPayments(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/useDestinationPayments`, { data, })
  },

  * updateMerchantEnableDisputeNotifications(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/dispute-notifications`, { data, })
  },

  * updateMerchantEnableFailedPayoutNotifications(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/failed-payout-notifications`, { data, })
  },

  * updateMerchantEnableBankingUpdateNotifications(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/banking-update-notifications`, { data, })
  },

  * updateDualProcessorRefunds(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/dual-refunds`, { data, })
  },

  * updateAllowRefundsAndVoids(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/refunds-and-voids`, { data, })
  },

  * updateStackWorksDisputes(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/stack-works-disputes`, { data, })
  },

  * updateAllowSubscriptions(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/allow-subscriptions`, { data, })
  },

  * updateAllowCustomers(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/allow-customers`, { data, })
  },

  * updateAllowCheckout(merchantId, data) {
		if (data) {
			return yield call(api.patch, `/admin/merchants/${merchantId}/checkout`, { data, })
		}
		else {
			return yield call(api.delete, `/admin/merchants/${merchantId}/checkout`, { data, })
		}
  },

  * updateStackOwned(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/stack-owned`, { data, })
  },

  * updateMerchantClient(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/client`, { client: data.client_id, })
  },

  * updateMerchantSoftDescriptor(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/soft-descriptor`, data)
  },

  * updateMerchantPayoutDescriptor(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/payout-descriptor`, data)
  },

  * updateMerchantTier(merchantId, data) {
    const rate_name = data.web_rate_name || null
    const mobile_rate_name = data.mobile_rate_name || null
    return yield call(api.post, `/admin/merchants/${merchantId}/tier`, { rate_name, mobile_rate_name })
  },

  * updateSmallDollarTransaction(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/small-dollar-transaction`, data)
  },

  * updateBankAccountInfo(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/banking-info`, { data, })
  },

  * updateFundingHold(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/funding-hold`, { data, })
  },

  * updateLimits(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/limits-settings`, {
      cc_per_transaction_limit: data.ccPerTransactionLimit,
      cc_per_month_limit: data.ccMonthlyLimit,
      ach_per_transaction_limit: data.achPerTransactionLimit,
      ach_per_month_limit: data.achMonthlyLimit,
      cc_refund_line_of_credit: data.ccRefundLineOfCredit,
    })
  },
  * sendBeneficialData(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/beneficial-data`, { data, })
  },
  * fetchMerchantAlerts(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/alert`)
  },
  * createMerchantAlert(merchantId, data) {
    return yield call(api.post, `/admin/merchants/${merchantId}/alert`, { data, })
  },
  * activateMerchantAlert(merchantId, alertId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/alert/${alertId}/activate`)
  },
  * deactivateMerchantAlert(merchantId, alertId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/alert/${alertId}/deactivate`)
  },
  * deleteMerchantAlert(merchantId, alertId) {
    return yield call(api.delete, `/admin/merchants/${merchantId}/alert/${alertId}`)
  },

  * adminEnableUser(id) {
    return yield call(api.post, '/v2/admin/users/enable', { id, })
  },

  * adminDisableUser(id) {
    return yield call(api.post, '/v2/admin/users/disable', { id, })
  },

  * adminDeleteUser(id) {
    return yield call(api.post, '/v2/admin/users/delete', { id, })
  },

  * resetUser(id) {
    return yield call(api.post, `/admin/users/${id}/reset`)
  },

  * editMerchantId({ payload, id, }) {
    return yield call(api.post, `/v2/admin/users/${id}/info`, { ...payload, })
  },

  * deleteFromMerchantByIds({ id, merchantId, }) {
    return yield call(api.delete, `/v2/admin/users/${id}/fromMerchant/${merchantId}`)
  },

  * editAdditionalStatusInfo({ payload, id, }) {
    return yield call(api.post, `/v2/admin/users/${id}/info`, { ...payload, })
  },

  * editMerchantAdminStatus({ payload, id, }) {
    return yield call(api.post, `/v2/admin/users/${id}/info`, { ...payload, })
  },

  * downloadUsers({ userType, sort, keyword, }) {
    const params = {
      group: userType,
      search: keyword,
      download: true,
    }

    const sortBy = Object.entries(sort).filter(([key, value, ]) => value !== '')
    if (sortBy && sortBy.length) {
      const [property, direction, ] = sortBy[0]
      params.sort = property
      params.dir = direction
    }

    return yield call(api.get, '/v2/admin/users', { params, })
  },

  * fetchOutageTransactions({ filters, pagination, sort, }) {
    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
    }

    for (const property in sort) {
      if (sort.hasOwnProperty(property) && sort[property] !== '') {
        params.sort = property
        params.dir = sort[property]
      }
    }

    return yield call(api.get, `/admin/outage-transactions`, { params, })
  },

  * downloadOutageTransactions({ filters, sort, }) {
    const params = {
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      download: true,
    }

    for (const property in sort) {
      if (sort.hasOwnProperty(property) && sort[property] !== '') {
        params.sort = property
        params.dir = sort[property]
      }
    }

    return yield call(api.get, `/admin/outage-transactions`, { params, })
  },
  * downloadOutageTransactionsDetailed({ filters, sort, }) {
    const params = {
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      download: true,
      detailed: true,
    }

    for (const property in sort) {
      if (sort.hasOwnProperty(property) && sort[property] !== '') {
        params.sort = property
        params.dir = sort[property]
      }
    }

    return yield call(api.get, `/admin/outage-transactions`, { params, })
  },

  * fetchEmailHistory({ filters, pagination }) {
    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      email: filters.email,
      event: filters.event,
    }

    return yield call(api.get, `/admin/email-history`, { params, })
  },

  * downloadEmailHistory({ filters }) {
    const params = {
      email: filters.email,
      event: filters.event,
    }

    return yield call(api.get, `/admin/email-history/download`, { params, })
  },

  * fetchInterchangeReport({ merchantId, filters, pagination, sort, }) {
    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      merchant_id: merchantId,
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      sort: buildSortString(sort),
    }

    return yield call(api.get, `/admin/interchange/report`, { params, })
  },

  * downloadInterchangeReport({ merchantId, filters, sort, }) {
    const params = {
      merchant_id: merchantId,
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      sort: buildSortString(sort),
      download: true,
    }

    return yield call(api.get, `/admin/interchange/report`, { params, })
  },

  * fetchStripeInterchangeReport({ merchantId, filters, pagination, sort, }) {
    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      merchant_id: merchantId,
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      sort: buildSortString(sort),
    }

    return yield call(api.get, `/admin/interchange/stripe-report`, { params, })
  },

  * downloadStripeInterchangeReport({ merchantId, filters, sort, }) {
    const params = {
      merchant_id: merchantId,
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      sort: buildSortString(sort),
      download: true,
    }

    return yield call(api.get, `/admin/interchange/stripe-report`, { params, })
  },

  * fetchResidualFees({ filters, pagination, sorts, search }) {
    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      filters: filters.checkboxFilters,
      sorts,
      search
    }

    return yield call(api.get, `/admin/residual-fees`, { params, })
  },

  * downloadResidualFees({ filters, sorts, search }) {
    const params = {
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      sorts,
      search,
      download: true,
      preFormattedColNames: true,
    }

    return yield call(api.get, `/admin/residual-fees/download`, { params, })
  },

  * fetchResidualFeesOverview({ filters, }) {
    const params = {
      start_date: moment(filters.from_date).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).utc().format('YYYY-MM-DD HH:mm:ss')
    }

    return yield call(api.get, `/admin/residual-fees/overview`, { params, })
  },

  * fetchSubscriptions({ filters, pagination, sorts, search }) {
    const params = {
      page: pagination.page,
      per_page: pagination.perPage,
      start_date: moment(filters.from_date).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(filters.to_date).format('YYYY-MM-DD HH:mm:ss'),
      sorts,
      search,
      filters: filters.checkboxFilters,
    }

    return yield call(api.get, `/admin/subscriptions`, { params, })
  },

  * migrateAccount(data) {
    return yield call(api.post, `/admin/merchants/migrate`, data)
  },

  * completeMigrations(data) {
    return yield call(api.post, `/admin/merchants/complete-migrations`, data)
  },

  * fetchPendingMigrations(filters) {
    return yield call(api.get, '/admin/merchants/pending-migrations', { params: filters })
  },

  * fetchPendingMigrationsDownload() {
    const params = {
      download: true,
    }
    return yield call(api.get, '/admin/merchants/pending-migrations', { params, })
  },

  * fetchClientList() {
    return yield call(api.get, '/admin/clients/')
  },

  * fetchAdminRoles() {
    return yield call(api.get, '/admin/users/roles')
  },

  * fetchSiteSettings() {
    return yield call(api.get, '/settings/site-settings')
  },

  * fetchAccountNumber(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/bank-account`)
  },

  * fetchFullSsnNumber(merchantId, merchantContactId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/full-ssn`, { params: { merchantContactId } })
  },

  * fetchMerchantVolumeRank(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/volume/rank`)
  },

  * fetchPlatformBalance(countryCode, date) {
    return yield call(api.get, `/admin/stripe-platform-balance`, {
      timeout: 300000, // 5 minutes
      params: { countryCode, date }
    })
  },
  * fetchLastPayoutDate(countryCode) {
    return yield call(api.get, `/admin/stripe-platform-balance/last-payout-date`, { params: { countryCode } })
  },
  * fetchHistory(countryCode, page = 1) {
    return yield call(api.get, `/admin/stripe-platform-balance/history`, { params: { countryCode, page } })
  },
  * performPayout(countryCode, toDate) {
    return yield call(api.post, `/admin/stripe-platform-balance`, { countryCode, toDate })
  },

  * fetchCTAList(pagination) {
    const params = {
      page: _.get(pagination, 'page', 1),
      per_page: _.get(pagination, 'perPage', 10)
    }

    return yield call(api.get, '/admin/cta', { params, })
  },

  * createCTA(params) {
    return yield call(api.post, `/admin/cta`, params)
  },
  * fetchCTACategories() {
    return yield call(api.get, `/admin/cta/categories`)
  },
  * fetchCtaEmailTemplates() {
    return yield call(api.get, `/admin/cta/email-templates`)
  },
  * assignMerchantToCTA(params) {
    return yield call(api.post, `/admin/cta/${params.data.ctaId}/merchant/${params.data.mid}`)
  },
  * resendCTANotification(ctaId) {
    return yield call(api.post, `/admin/cta/${ctaId}/resend`)
  },
  * fetchCta(ctaId, params) {
    return yield call(api.get, `/admin/cta/${ctaId}`, params)
  },
  * deleteCta(ctaId, params) {
    return yield call(api.delete, `/admin/cta/${ctaId}`, params)
  },
  * deleteMidFromCta(params) {
    return yield call(api.delete, `/admin/cta/${params.ctaId}/merchant/${params.mid}`)
  },
  * updateCta(ctaId, params) {
    return yield call(api.patch, `/admin/cta/${ctaId}`, params)
  },

  * fetchNotificationEmails(merchantId, params) {
    return yield call(api.get, `/admin/merchants/${merchantId}/notifications/subscriptions`, params)
  },
  * addNotificationEmail(merchantId, itype, params) {
    return yield call(api.post, `/admin/merchants/${merchantId}/notifications/${itype}/subscriptions`, params)
  },
  * removeNotificationEmail(merchantId, itype, subscriptionId) {
    return yield call(api.delete,
      `/admin/merchants/${merchantId}/notifications/${itype}/subscriptions/${subscriptionId}`)
  },

  * fetchCardBrandSettings(merchantId) {
    return yield call(api.get, `/admin/merchants/${merchantId}/card-brands/`)
  },
  * enableCardBrand(merchantId, cType) {
    return yield call(api.post, `/admin/merchants/${merchantId}/card-brands/${cType}/`)
  },
  * disableCardBrand(merchantId, cType) {
    return yield call(api.delete, `/admin/merchants/${merchantId}/card-brands/${cType}`)
  },
  * updateMerchantSettings(merchantId, settingsData) {
    const payload = {
      allow_card_payment: settingsData.allow_card_payment,
      allow_ach_payment: settingsData.allow_ach_payment,
    }

    return yield call(api.post, `/admin/merchants/${merchantId}/settings`, payload)
  },

  * fetchMerchantDocuments({ merchantId, documentType = null }) {
    const documentTypeArray = documentType && documentType.split(',')

    const validDocumentTypes = (documentTypeArray && documentTypeArray
      .map((value) => parseFloat(value))
      .filter((value) => !isNaN(value))) || []

    const params = {
      ...(validDocumentTypes.length > 0 ? { document_type: validDocumentTypes.join(',') } : {})
    }

    return yield call(api.get, `/admin/merchants/${merchantId}/documents`, { params, })
  },

  * fetchDocumentTypeFilterOptions() {
    return yield call(api.get, `/admin/merchants/documents/types`)
  },

  * fetchMerchantDocumentFile({ fileId, }) {
    return yield call(api.get, `/files/${fileId}`, {
      responseType: 'arraybuffer'
    })
  },

  * deleteMerchantDocument(merchantId, documentId) {
    return yield call(api.delete, `/admin/merchants/${merchantId}/documents/${documentId}`)
  },

  * sendMerchantDocument(merchantId, documentId) {
    return yield call(api.post, `/admin/merchants/${merchantId}/documents-send/${documentId}`)
  },

  * downloadScheduledTransactions({ merchantId }) {
    return yield call(api.get, `/admin/merchants/${merchantId}/scheduled-trans/download`)
  },
})


export default AdminApi
